document.addEventListener("notify", function(ev) {
  if (!ev.detail) throw "no notification in event.target";
  let n = document.createElement("div");
  n.id = "notification";
  document.body.appendChild(n);
  if (typeof ev.detail === "string") {
    n.innerHTML = ev.detail;
  } else if (ev.detail.nodeName && ev.detail.nodeName === "TEMPLATE") {
    n.appendChild(ev.detail.content.cloneNode(true));
  } else {
    n.appendChild(ev.detail);
  }
  n.classList.add("visible");
  setTimeout(() => n.remove(), 2500);
});

let counter = 1;
//TODO: remove 
window.test = function() {
  let d = document.createElement("div");
  d.innerHTML = `<h2>Test</h2><p>dit is test nr ${counter++}</p>`;
  document.dispatchEvent(new CustomEvent("notify", { detail: d }));

};
