// import Checkout from "./Checkout.js";
import { model } from "./config.js";
import Localizer from "./Localizer.js";
let localizer = new Localizer();
/**
* creates shipping-form element
*/
class ShippingForm extends HTMLElement {
  constructor() {
    super();
    this.state = "button";
  }
  connectedCallback() {
    this.editable = true;
    if (this.dataset.editable && this.dataset.editable === "false") this.editable = false;
    this.innerHTML = `-`;
    this.valid ? this.state = "cost" : this.state = "button";
  }
  /**
  * set country to ship to and render
  */
  set country(country) {
    this._country = country;
    if (this.valid) this.state = "cost";
    this.render();
  }
  get country() { return this._country; }
  /**
  * set postalcode to ship to and render
  */
  set postalcode(postalcode) {
    this._postalcode = postalcode;
    if (this.valid) this.state = "cost";
    this.render();
  }
  get postalcode() { return this._postalcode; }
  /**
  * set shipping_cost and render
  */
  set shipping_cost(c) {
    this._shipping_cost = c;
    if (c !== undefined) this.state = "cost";
    this.render();
  }
  get shipping_cost() { return this._shipping_cost; }
  /** set error */
  set error(err) {
    this._error = err;
    if (err === undefined) {
      this.valid ? this.state = "cost" : this.state = "button";
    } else {
      this._shipping_cost = undefined;
      this.state = "error";
    }
    this.render();
  }
  get error() { return this._error; }
  /**
  * check if shippingcost can be calculated
  */
  get valid() {
    if (this.country === undefined || this.country.length === 0 || this.country === "none") return false;
    if (this.postalcode === undefined || this.postalcode.length === 0) return false;
    return true;
  }
  render() {
    let el, ml;
    while (this.firstChild) this.firstChild.remove();
    model.onload(function() {
      this.classList.remove("error");
      switch (this.state) {
        case "error":
          el = document.createElement("span");
          el.dataset.localize= "Wij kunnen helaas niet naar uw gebied versturen";
          this.appendChild(el);
          if (this.editable) {
            el = document.createElement("span");
            el.id = "show_form";
            el.classList.add("clickable");
            el.innerText = " ...";
            this.appendChild(el);
          }
          this.classList.add("error");
          break;
        case "cost":
          el = document.createElement("span");
          if (this._shipping_cost !== undefined) {
            el.innerText = `€ ${this._shipping_cost.toFixed(2)} `;
          } else {
            el.innerText = '-';
          }
          this.appendChild(el);
          if (this.editable) {
            el = document.createElement("span");
            el.id = "show_form";
            el.classList.add("clickable");
            el.innerText = " ...";
            this.appendChild(el);
          }
          break;
        case "button":
          if (this.editable) {
            el = document.createElement("button");
            el.id = "calculate";
            ml = document.createElement("span");
            ml.dataset.localize = "Inschatten";
            el.appendChild(ml);
            this.appendChild(el);
            this.querySelector("button#calculate").addEventListener("click", function() {
              this.state = "form";
              this.render();
              return;
            }.bind(this));
          } else {
            this.innerText = "-";
          }
          break;
        case "form":
          if (this.editable) {
            el = document.createElement("span");
            el.dataset.localize = "Land";
            this.appendChild(el);
            el = document.createElement("country-picker");
            el.dataset.country = this.country || "none";
            this.appendChild(el);
            el = document.createElement("span");
            el.dataset.localize = "Postcode";
            this.appendChild(el);
            el = document.createElement("input");
            el.type = "text";
            el.id = "postalcode";
            el.value = this.postalcode || "";
            this.appendChild(el);
            this.querySelector("country-picker").addEventListener("change", function(e) {
              this.postalcode = undefined;
              this.country = e.target.value;
              if (this.country === "none") this.country = undefined;
              this.dispatchEvent(new CustomEvent("change", { detail: { postalcode: this.postalcode, country: this.country, valid: this.valid } }));
              e.stopPropagation();
            }.bind(this));
            this.querySelector("input#postalcode").addEventListener("change", function(e) {
              this.postalcode = e.target.value;
              if (this.postalcode === "") this.postalcode = undefined;
              this.dispatchEvent(new CustomEvent("change", { detail: { postalcode: this.postalcode, country: this.country, valid: this.valid } }));
              e.stopPropagation();
            }.bind(this));
          } else {
            this.innerText = "-";
          }
          break;
      }
      if (this.editable === true) {
        let span = this.querySelector("span#show_form");
        if (span) span.addEventListener("click", function() {
          this.state = "form";
          this.render();
        }.bind(this));
      }
      localizer.localize(this);
    }.bind(this));
  }
}
customElements.define("shipping-form", ShippingForm);
