import Checkout from "./Checkout.js";

/**
* creates shopping-cart icon element.
*/
class ShoppingCartIcon extends HTMLElement {
  constructor() {
    super();
  }
  connectedCallback() {
    this.innerHTML = `
      <a href="${this.getAttribute("href")}">
          <span class="badge">0</span>
          <svg id="cart" width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M54.9547 49.6575H20.9803L20.2611 46.725H58.7637L65 19.6378L14.1336 13.5633L11.8027 0H0V5.12321H7.46967L8.80937 12.9251L8.70061 12.9128L14.8429 46.7275H14.9715L15.7204 49.7758C12.082 50.382 9.29631 53.5338 9.29631 57.3312C9.29631 61.5599 12.7469 65 16.9884 65C21.23 65 24.6806 61.5599 24.6806 57.3312C24.6806 56.4391 24.5174 55.584 24.2357 54.7856H47.7075C47.4258 55.584 47.2626 56.4391 47.2626 57.3312C47.2626 61.5599 50.7132 65 54.9547 65C59.1963 65 62.6469 61.5599 62.6469 57.3312C62.6469 53.1025 59.1963 49.6624 54.9547 49.6624V49.6575ZM58.7143 24.0488L54.673 41.6018H19.1339L15.7377 22.898L15.0382 18.827L58.7143 24.0488ZM19.5418 57.3287C19.5418 58.7309 18.3973 59.8743 16.9884 59.8743C15.5795 59.8743 14.4351 58.7309 14.4351 57.3287C14.4351 55.9266 15.5795 54.7831 16.9884 54.7831C18.3973 54.7831 19.5418 55.9266 19.5418 57.3287ZM54.9547 59.8743C53.5483 59.8743 52.4014 58.7309 52.4014 57.3287C52.4014 55.9266 53.5483 54.7831 54.9547 54.7831C56.3612 54.7831 57.5081 55.9266 57.5081 57.3287C57.5081 58.7309 56.3637 59.8743 54.9547 59.8743Z"/></svg>
      </a>
    `;
    this.updateCount(new Checkout());
    document.addEventListener("checkout_change", (e) => this.updateCount(e.detail.checkout));
  }
  updateCount(checkout) {
    this.querySelector("span.badge").innerText = checkout.count;
  }
}
customElements.define("shopping-cart-icon", ShoppingCartIcon);
