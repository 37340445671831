class PaymentMethod extends HTMLElement {
  constructor() {
    super();
  }
  connectedCallback() {
    console.log(this.dataset.method);
    this.innerHTML = `
      <img src="/images/checkout/${this.dataset.method}.svg" alt="${this.dataset.method}" />
      <span>${this.dataset.label || this.dataset.method}</span>
    `;
    this.addEventListener("click", function(e) {
      this.selected = true;
    }.bind(this));
  }
  get selected() {
    return this.classList.contains("selected");
  }
  set selected(value) {
    if (value === true) {
      document.querySelectorAll("payment-method").forEach(el => el.classList.remove("selected"));
      this.classList.add("selected");
      this.dispatchEvent(new CustomEvent("change", { detail: { method: this.dataset.method } }));
    } else {
      this.classList.remove("selected");
    }

  }
}
customElements.define("payment-method", PaymentMethod);

