import Checkout from "./Checkout.js";

/**
* creates shopping-cart icon element.
*/
class ShoppingCartIcon extends HTMLElement {
  constructor() {
    super();
  }
  connectedCallback() {
    this.innerHTML = `
      <a href="${this.getAttribute("href")}">
          <span class="badge">0</span>
          <i class="material-icons">shopping_cart</i>
      </a>
    `;
    this.updateCount(new Checkout());
    document.addEventListener("checkout_change", (e) => this.updateCount(e.detail.checkout));
  }
  updateCount(checkout) {
    this.querySelector("span.badge").innerText = checkout.count;
  }
}
customElements.define("shopping-cart-icon", ShoppingCartIcon);
