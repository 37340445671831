import { api_url } from "./config.js";

class SendMessage extends HTMLElement {
  constructor() {
    super();
  }
  connectedCallback() {
    let btn = document.createElement("button");
    btn.classList.add("cta");
    btn.innerText = this.innerText;
    this.innerText = "";
    this.appendChild(btn);
    btn.addEventListener("click", this.send.bind(this));
  }
  get message() {
    let msg = {};
    msg.first_name = this.dataset.first_name || document.querySelector("#first_name").value;
    msg.last_name = this.dataset.last_name || document.querySelector("#last_name").value;
    msg.email = this.dataset.email || document.querySelector("#email").value;
    msg.subject = this.dataset.subject || document.querySelector("#subject").value;
    msg.message = this.dataset.message || document.querySelector("#message").value;
    msg.privacy = this.dataset.privacy === "1" || document.querySelector("#privacy").checked;
    return msg;
  }
  send() {
    let msg = this.message;
    if (!valid_msg(msg)) {
      invalid(msg);
      return undefined;
    }
    valid(msg);
    fetch(api_url + "/message", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(msg)
    }).then(this.success.bind(this)).catch(this.fail.bind(this));
  }
  success(data) {
    if (data.ok) {
      let tmpl = document.querySelector("template#message_success");
      if (!tmpl) {
        tmpl = document.createElement("div"); 
        tmpl.innerText = "Success"; 
      }
      document.dispatchEvent(new CustomEvent("notify", { detail: tmpl }));
      this.clear();
    } else {
      this.fail(data);
    }
  }
  fail(data) {
    console.warn("Failed to send message:",data);
    let tmpl = document.querySelector("template#message_fail");
    if (!tmpl) {
      tmpl = document.createElement("div"); 
      tmpl.innerText = "Failed"; 
    }
    document.dispatchEvent(new CustomEvent("notify", { detail: tmpl }));
  }
  clear() {
    let fn = document.querySelector("#first_name");
    if (fn) fn.value = "";
    let ln = document.querySelector("#last_name");
    if (ln) ln.value = "";
    let em = document.querySelector("#email");
    if (em) em.value = "";
    let su = document.querySelector("#subject");
    if (su) su.value = "";
    let ms = document.querySelector("#message");
    if (ms) ms.value = "";
    let pr = document.querySelector("#privacy");
    if (pr) pr.checked = false;
    valid();
  }
}
customElements.define("send-message", SendMessage);

function valid_msg(msg) {
  if (!msg.first_name || msg.first_name.length === 0) return false;
  if (!msg.last_name || msg.last_name.length === 0) return false;
  if (!msg.email || msg.email.length === 0) return false;
  if (!msg.subject || msg.subject.length === 0) return false;
  if (!msg.message || msg.message.length === 0) return false;
  if (!msg.privacy) return false;
  return true;
}
function valid(msg) {
  if (msg) console.log("Valid message:", msg);
  let fn = document.querySelector("#first_name");
  if (fn) fn.classList.remove("invalid");
  let ln = document.querySelector("#last_name");
  if (ln) ln.classList.remove("invalid");
  let em = document.querySelector("#email");
  if (em) em.classList.remove("invalid");
  let su = document.querySelector("#subject");
  if (su) su.classList.remove("invalid");
  let ms = document.querySelector("#message");
  if (ms) ms.classList.remove("invalid");
  let pr = document.querySelector("#privacy");
  if (pr) pr.classList.remove("invalid");
}
function invalid(msg) {
  if (!msg) throw "no msg";
  console.warn("Invalid message:", msg);
  valid();
  let fn = document.querySelector("#first_name");
  if (fn && (!msg.first_name || msg.first_name.length === 0)) fn.classList.add("invalid");
  let ln = document.querySelector("#last_name");
  if (ln && (!msg.last_name || msg.last_name.length === 0)) ln.classList.add("invalid");
  let em = document.querySelector("#email");
  if (em && (!msg.email || msg.email.length === 0)) em.classList.add("invalid");
  let su = document.querySelector("#subject");
  if (su && (!msg.subject || msg.subject.length === 0)) su.classList.add("invalid");
  let ms = document.querySelector("#message");
  if (ms && (!msg.message || msg.message.length === 0)) ms.classList.add("invalid");
  let pr = document.querySelector("#privacy");
  if (pr && (!msg.privacy)) pr.classList.add("invalid");
}
