
class QuantityBox extends HTMLElement {
  constructor() {
    super();
  }
  connectedCallback() {
    this.innerHTML = `${trash}<span id="minus" class="hidden">-</span><input type="number" id="quantity" value="${this.value}" /><span id="plus">+</span>`;
    this.updateIcons();
    this.querySelector("input#quantity").addEventListener("change", function(e) {
      e.stopPropagation();
      this.value = e.target.value;
    }.bind(this));
    this.querySelectorAll("span, svg").forEach(function(el) {
      el.addEventListener("click", function(e) {
        switch (e.target.id) {
          case "trash":
            this.dispatchEvent(new CustomEvent("delete"));
            break;
          case "plus":
            this.value = this.value + 1;
            this.querySelector("input").value = this.value;
            this.updateIcons();
            break;
          case "minus":
            this.value = this.value - 1;
            this.querySelector("input").value = this.value;
            this.updateIcons();
            break;
        }
      }.bind(this));
    }.bind(this));
  }
  updateIcons() {
    if (this.value == 1) {
      this.querySelector("svg").classList.remove("hidden");
      this.querySelector("span#minus").classList.add("hidden");
    } else {
      this.querySelector("svg").classList.add("hidden");
      this.querySelector("span#minus").classList.remove("hidden");
    }
  }
  get value() {
    return parseInt(this.getAttribute("value"), 10) || 0;
  }
  set value(val) {
    let intval = parseInt(val, 10);
    if (isNaN(intval)) { intval = 0; }
    this.setAttribute("value", intval);
    this.dispatchEvent(new CustomEvent("change", { detail: { value: intval } }));
  }
}
customElements.define("quantity-box", QuantityBox);

const trash = `<svg id="trash" class="hidden" viewBox="0 0 21 26"><g><path d="M18.4736 5.85008C18.1109 5.81758 17.8245 6.11658 17.8182 6.47408L17.2009 24.6936H4.43545L3.81818 6.47408C3.80545 6.11658 3.5 5.81758 3.16273 5.85008C2.81273 5.86308 2.53909 6.16208 2.55182 6.51958L3.18818 25.3696C3.20091 25.7206 3.48091 26.0001 3.82455 26.0001H17.8245C18.1682 26.0001 18.4482 25.7206 18.4609 25.3696L19.0973 6.51958C19.11 6.16208 18.8364 5.86308 18.4864 5.85008H18.4736Z" fill="#3B3735"/><path d="M13.6818 18.8496V6.49961C13.6818 6.14211 13.3955 5.84961 13.0455 5.84961C12.6955 5.84961 12.4091 6.14211 12.4091 6.49961V18.8496C12.4091 19.2071 12.6955 19.4996 13.0455 19.4996C13.3955 19.4996 13.6818 19.2071 13.6818 18.8496Z" fill="#3B3735"/><path d="M9.22727 18.8496V6.49961C9.22727 6.14211 8.94091 5.84961 8.59091 5.84961C8.24091 5.84961 7.95454 6.14211 7.95454 6.49961V18.8496C7.95454 19.2071 8.24091 19.4996 8.59091 19.4996C8.94091 19.4996 9.22727 19.2071 9.22727 18.8496Z" fill="#3B3735"/><path d="M20.3636 1.95H12.6891C12.7145 1.846 12.7273 1.7355 12.7273 1.625C12.7273 0.728 11.8745 0 10.8182 0C9.76182 0 8.90909 0.728 8.90909 1.625C8.90909 1.7355 8.92182 1.846 8.94727 1.95H0.636364C0.286364 1.95 0 2.2425 0 2.6C0 2.9575 0.286364 3.25 0.636364 3.25H20.3636C20.7136 3.25 21 2.9575 21 2.6C21 2.2425 20.7136 1.95 20.3636 1.95Z" fill="#3B3735"/></g></svg>`;
