import Model from "./Model.js";

/**
 * constants
*/
export const api_url = "https://api.horto-meo.com";
//export const api_url = "http://jos-desktop.muysers.nl:9777";
//export const api_url = "http://api-horto-meo_container:9777";
export const data_url = "/data.json";
export const vat_low = 9;
export const vat_high = 21;

/**
 * global model instance, caches data
*/
export const model = new Model();
window.model = model; //DEBUG:
