// import config from "./config.js";
import Localizer from "./Localizer.js";
import Checkout from "./Checkout.js";
import { model } from "./config.js";
let localizer = new Localizer();

class CheckoutForm extends HTMLElement {
  constructor() {
    super();
  }
  connectedCallback() {
    let e, k;
    this._checkout = new Checkout();
    this.step = this.dataset.step;
    document.querySelectorAll("input, country-picker, payment-method").forEach((i) => i.addEventListener("change", this.inputChange.bind(this)));
    for (k in this._checkout) {
      if (Object.prototype.hasOwnProperty.call(this._checkout, k)) {
        e = document.querySelector(`input#${k}, country-picker#${k}`);
        if (e) {
          if (e.type === "radio") {
            document.querySelectorAll(`input#${k}`).forEach((b) => b.value === this._checkout[k] ? (b.checked = true) : (b.checked = false));
          } else {
            e.value = this._checkout[k];
          }
        }
      }
    }
    if (this._checkout["alt_shipping_address"] && this._checkout["alt_shipping_address"] === "1") {
      document.querySelector("section#address input#alt_shipping_address").checked = true;
      document.querySelector("section#address div#shipping_address").classList.remove("hidden");
      document.querySelector("section#address h3#header_billing_and_shipping").classList.add("hidden");
      document.querySelector("section#address h3#header_billing_address").classList.remove("hidden");
    }
    if (this._checkout["payment_method"]) {
      let pm = document.querySelector(`payment-method[data-method="${this._checkout["payment_method"]}"]`);
      if (pm) { pm.classList.add("selected"); }
    }
    document.querySelectorAll("button#prev").forEach((b) => b.addEventListener("click", this.previous.bind(this)));
    document.querySelectorAll("button#next").forEach((b) => b.addEventListener("click", this.next.bind(this)));
    document.querySelector("section#shipping button#place-order").addEventListener("click", this.place_order.bind(this));
  }
  /**
  * navigate checkout form
  */
  set step(step) {
    this.dataset.step = step;
    document.querySelectorAll("main#checkout section").forEach((s) => s.classList.add("hidden"));
    let s = document.querySelector("main#checkout section#" + step);
    if (s) s.classList.remove("hidden");
    switch (step) {
      case "address":
        break;
      case "payment":
        break;
      case "shipping":
        this.show_order();
        break;
    }
  }
  /**
  * returns current checkout step
  */
  get step() {
    return this.dataset.step;
  }
  /**
  * set key to value, save to localStorage
  */
  set(key, value) {
    this._checkout[key] = value;
    this._checkout.save();
    this.dispatchEvent(new CustomEvent("change", { detail: this._checkout }));
  }
  /**
  * remove keys (array) from this._checkout and save to local storage
  */
  unset(keys) {
    if (Array.isArray(keys) === true) {
      for (let k of keys) {
        delete this._checkout[k];
        document.querySelector(`input#${k}, country-picker#${k}`).value = "";
      }
    } else {
      delete this._checkout[keys];
      document.querySelector(`input#${keys}, country-picker#${keys}`).value = "";
    }
    this._checkout.save();
    this.dispatchEvent(new CustomEvent("change", { detail: this._checkout }));
  }
  /**
  * navigate to previous step
  */
  previous() {
    let l = localizer.language;
    switch (this.step) {
      case "address":
        switch (l) {
          case "de":
            document.location = "/warenkorb";
            break;
          case "en":
            document.location = "/cart";
            break;
          case "nl":
            document.location = "/winkelwagen";
            break;
        }
        break;
      case "shipping":
        this.step = "payment";
        break;
      case "payment":
        this.step = "address";
        break;
    }
  }
  /**
  * navigate to next step
  */
  next() {
    if (!this.validateForm()) return;
    switch (this.step) {
      case "address":
        this.step = "payment";
        break;
      case "payment":
        this.step = "shipping";
        break;
      case "shipping":
        break;
    }
  }
  /**
  * callback for input change event. validate input and set data
  */
  inputChange(e) {
    if (e.target.id === "alt_shipping_address") {
      let d = document.querySelector("section#address div#shipping_address");
      if (e.target.checked) {
        d.classList.remove("hidden");
        document.querySelector("section#address h3#header_billing_and_shipping").classList.add("hidden");
        document.querySelector("section#address h3#header_billing_address").classList.remove("hidden");
      } else {
        d.classList.add("hidden");
        document.querySelector("section#address h3#header_billing_address").classList.add("hidden");
        document.querySelector("section#address h3#header_billing_and_shipping").classList.remove("hidden");
        this.unset(["ship_city", "ship_address", "ship_postalcode", "ship_country"]);
      }
    }
    if (this.validateInput(e.target)) {
      let i = e.target.id;// translateKey(e.target.id);
      if (e.target.type === "checkbox") {
        this.set(i, e.target.checked ? "1" : "0");
        e.target.parentElement.classList.remove("invalid");
      } else if (e.target.nodeName==="PAYMENT-METHOD") {
        this.set("payment_method", e.target.dataset.method);
      } else {
        this.set(i, e.target.value);
        e.target.classList.remove("invalid");
      }
    } else {
      console.warn("Invalid input value:", e.target.id, e.target.value);
      if (e.target.type === "radio" || e.target.type === "checkbox") {
        e.target.parentElement.classList.add("invalid");
      } else {
        e.target.classList.add("invalid");
      }
    }
  }
  /**
  * validates all input on form for current step
  */
  validateForm() {
    let inputs = document.querySelectorAll(`section#${this.step} input, section#${this.step} country-picker, section#${this.step} payment-method`);
    let valid = true;
    for (let i = 0; i < inputs.length; i++) {
      if (this.validateInput(inputs[i]) === false) {
        if (inputs[i].type === "radio" || inputs[i].type === "checkbox") {
          inputs[i].parentNode.classList.add("invalid");
        } else if (inputs[i].nodeName==="PAYMENT-METHOD") {
          document.querySelector("#payment-method-warning").classList.remove("hidden");
        } else {
          inputs[i].classList.add("invalid");
        }
        valid = false;
      } else {
        if (inputs[i].type === "radio") {
          inputs[i].parentNode.classList.remove("invalid");
        } else if (inputs[i].nodeName==="PAYMENT-METHOD") {
          document.querySelector("#payment-method-warning").classList.add("hidden");
        } else {
          inputs[i].classList.remove("invalid");
        }
      }
    }
    valid ? console.log(`Form ${this.step} is valid`) : console.warn(`Form ${this.step} is invalid`);
    return valid;
  }
  /**
  * validate input
  */
  validateInput(e) {
    let inputs, i;
    switch (e.id || e.nodeName) {
      case "PAYMENT-METHOD":
      case "zahlungsart":
      case "payment_method":
      case "betaalmethode":
        inputs = document.querySelectorAll("payment-method");
        for (i = 0; i < inputs.length; i++) {
          if (inputs[i].selected === true) return true;
        }
        return false;
      case "land":
      case "country":
        if (e.dataset.country === "none") return false;
        return true;
      case "ship_address":
      case "ship_city":
      case "ship_postalcode":
        if (this._checkout.alt_shipping_address === "1" && e.value.length === 0) return false;
        return true;
      case "ship_country":
        if (this._checkout.alt_shipping_address === "1" && e.value === "none") return false;
        return true;
      case "accept_conditions":
        if (e.checked === false) {
          if (this._checkout.accept_conditions !== undefined) delete this._checkout.accept_conditions;
          this._checkout.save();
          return false;
        }
        return true;
      default:
        if (!e.value) { return false; }
        if (e.value.length === 0) { return false; }
    }
    return true;
  }
  clear_order() {
    let checkout_items = document.querySelector("section#shipping div#checkout_items");
    let checkout_totals = document.querySelector("section#shipping div#checkout_totals");
    while (checkout_items.firstElementChild) checkout_items.firstElementChild.remove();
    while (checkout_totals.firstElementChild) checkout_totals.firstElementChild.remove();
    checkout_items.classList.remove("invalid");
    checkout_totals.classList.remove("invalid");
    document.querySelector("div#conditions_container").classList.remove("invalid");
    document.querySelector("div#address_container").classList.remove("invalid");
    document.querySelector("section#shipping div#name").innerText = '-';
    document.querySelector("section#shipping div#address").innerText = '-';
    document.querySelector("section#shipping div#payment_method").innerText = '-';
  }
  async show_order() {
    this.clear_order();
    let checkout_items = document.querySelector("section#shipping div#checkout_items");
    let checkout_totals = document.querySelector("section#shipping div#checkout_totals");
    for (let item of this._checkout.local_items) {
      checkout_items.innerHTML += `<div><div>${item.quantity}</div><div>${item.plant.local_name} (${item.plant.latin_name})</div><div>${item.size.local_pot} ${item.size.local_size}</div><div>€ ${item.price}</div><div></div></div>`;
    }
    checkout_totals.innerHTML = `<div>
      <span data-localize="Bedrag"></span><span id="amount">€ ${this._checkout.item_amount.toFixed(2)}</span></div>
      <div><span data-localize="Verzendkosten"></span><span id="shipping_cost">-</span></div>
      <div><span data-localize="Totaalbedrag"></span><span id="total">-</span></div>
      <div><span data-localize="Totaalbedrag bevat BTW"></span><span id="vat">-</span></div>`;
    model.onload(()=>localizer.localize(checkout_totals));
    await this._checkout.calculate_shipping();
    let shipping_cost = this._checkout.shipping_cost;
    if (this._checkout._shipping_data.error === undefined) {
      document.querySelector("section#shipping button#place-order").disabled = false;
      if (shipping_cost) document.querySelector("section#shipping span#shipping_cost").innerText = `€ ${shipping_cost.toFixed(2)}`;
    } else {
      document.querySelector("section#shipping button#place-order").disabled = true;
      let el = document.createElement("span");
      el.dataset.localize = "Wij kunnen helaas niet naar uw gebied versturen";
      el.classList.add("error");
      model.onload(()=>localizer.localize(el));
      this.appendChild(el);
      let c = document.querySelector("section#shipping span#shipping_cost");
      while (c.firstChild) c.firstChild.remove();
      c.appendChild(el);
    }
    let amount = this._checkout.amount;
    let vat = this._checkout.vat;
    if (amount) document.querySelector("section#shipping span#total").innerText = `€ ${amount.toFixed(2)}`;
    if (vat) document.querySelector("section#shipping span#vat").innerText = `€ ${vat.toFixed(2)}`;
    document.querySelector("section#shipping div#name").innerText = `${this._checkout.first_name || "-"} ${this._checkout.last_name || "-"}`;
    document.querySelector("section#shipping div#address").innerHTML = `${this._checkout.ship_address || this._checkout.address}<br>${this._checkout.ship_postalcode || this._checkout.postalcode} ${this._checkout.ship_city || this._checkout.city}<br>${this._checkout.ship_country || this._checkout.country}`;
    document.querySelector("section#shipping div#payment_method").innerText = `${this._checkout.payment_method}`;
  }
  validate_order() {
    let v = this._checkout.valid;
    v !== "valid" ? console.warn("Validate order:", v) : console.info("Order is valid");
    switch (v) {
      case "valid":
        return true;
      case "conditions":
        document.querySelector("section#shipping div#conditions_container").classList.add("invalid");
        return false;
      case "address":
      case "name":
        document.querySelector("section#shipping div#address_container").classList.add("invalid");
        return false;
      case "items":
        document.querySelector("section#shipping div#checkout_items").classList.add("invalid");
        return false;
      case "totals":
        document.querySelector("section#shipping div#checkout_totals").classList.add("invalid");
        return false;
      default:
        return false;
    }
  }
  place_order() {
    if (this.validateForm() === true) {
      if (this.validate_order() === true) {
        document.querySelector("section#shipping button#place-order").disabled = true;
        this._checkout.place_order();
      }
    }
  }
}
customElements.define("checkout-form", CheckoutForm);

