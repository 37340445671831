import Model from "./Model.js";

/**
 * constants
*/
export const api_url = "https://api.horto-meo.com";
export const data_url = "/data.json";
export const localization_url = "/localization.json";
export const vat_low = 9;
export const vat_high = 21;

/**
 * global model instance, caches data
*/
export const model = new Model();
//window.model = model; //DEBUG:
