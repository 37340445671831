import Checkout from "./Checkout.js";
import { model } from "./config.js";
import Localizer from "./Localizer.js";
let localizer = new Localizer();

/**
* creates shopping-checkout element.
* show shopping checkout / totals
* allow to estimate shipping cost
*/
class ShoppingCart extends HTMLElement {
  constructor() {
    super();
  }
  connectedCallback() {
    this._checkout = new Checkout();
    model.onload(function() {
      this.innerHTML = `
        <div id="checkout_items"></div>
        <div id="checkout_totals">
          <div><span data-localize="Bedrag" class="label"></span><span id="amount" class="value">-</span></div>
          <div><span data-localize="Verzendkosten" class="label"></span><shipping-form class="value">></shipping-form></div>
          <div><span data-localize="Totaalbedrag" class="label"></span><span id="total" class="value">>-</span></div>
          <div><span data-localize="Totaalbedrag bevat BTW" class="label"></span><span id="vat" class="value">>-</span></div>
          <a id="checkout-button" class="button hidden cta" href="/kassa"  disabled data-localize="Verder naar de kassa"></a>
        </div>
        `;
      localizer.localize(this);
      document.querySelector("a#checkout-button").setAttribute("href", `/${localizer.localize("kassa")}`);
      this.showItems();
      this.showTotals();
      document.addEventListener("checkout_item_change", function() {
        this.clearItems();
        this.showItems();
        this.showTotals();
      }.bind(this));
      let ship = this.querySelector("shipping-form");
      if (ship) {
        ship.addEventListener("change", function(e) {
          //console.log("DEBUG ship:", e.detail);
          if (e.detail.postalcode !== undefined) {
            this._checkout.ship_postalcode !== undefined ? this._checkout.ship_postalcode = e.detail.postalcode : this._checkout.postalcode = e.detail.postalcode;
          } else {
            this._checkout.ship_postalcode !== undefined ? delete this._checkout.ship_postalcode : delete this._checkout.postalcode;
          }
          if (e.detail.country !== undefined) {
            this._checkout.ship_country !== undefined ? this._checkout.ship_country = e.detail.country : this._checkout.country = e.detail.country;
          } else {
            this._checkout.ship_country !== undefined ? delete this._checkout.ship_postalcode : delete this._checkout.postalcode;
          }
          this._checkout.save();
          this.showTotals();
        }.bind(this));
        ship._postalcode = this._checkout.ship_postalcode || this._checkout.postalcode || undefined;
        ship._country = this._checkout.ship_country || this._checkout.country || undefined;
        if (!this._checkout.can_calculate_shipping) ship.render();
      }
    }.bind(this));
  }
  /**
  * remove items from table and hide checkout button, does not remove from checkout 
  */
  clearItems() {
    let tbl = this.querySelector("div#checkout_items");
    while (tbl.firstElementChild) tbl.firstElementChild.remove();
    this.querySelector("a#checkout-button").classList.add("hidden");
  }
  /**
  * show items, show button and bind buttons
  */
  showItems() {
    model.onload(async function() {
      let tbl = this.querySelector("div#checkout_items");
      if (tbl) {
        let items = this._checkout.local_items;
        if (items != undefined && items.length > 0) {
          for (let i = 0; i < items.length; i++) {
            tbl.innerHTML += `<div data-namecode="${items[i].namecode}" data-sizecode="${items[i].sizecode}">
                <img src="/plantenfotos/${items[i].namecode}.jpg" />
                <quantity-box id="quantity" value="${items[i].quantity}"></quantity-box>
                <div class="item-title">${items[i].plant.local_name} (${items[i].plant.latin_name})</div>
                <div class="item-description">${items[i].size.local_pot} ${items[i].size.local_size}</div>
                <div class="item-price">&euro; ${items[i].price}</div>
              </div>`;
          }
          this.querySelector("a#checkout-button").classList.remove("hidden");
          tbl.querySelectorAll("quantity-box").forEach(function(i) {
            i.addEventListener("change", function(e) {
              let n = e.target.parentElement.dataset.namecode;
              let m = e.target.parentElement.dataset.sizecode;
              let a = e.target.value;
              this._checkout.update_item({ namecode: n, sizecode: m, quantity: a });
            }.bind(this));
            i.addEventListener("delete", function(e) {
              let n = e.target.parentElement.dataset.namecode;
              let m = e.target.parentElement.dataset.sizecode;
              this._checkout.update_item({ namecode: n, sizecode: m, quantity: 0 });
            }.bind(this));

          }.bind(this));
          tbl.querySelectorAll("button#remove").forEach(function(b) {
            b.addEventListener("click", function(e) {
              let n = e.target.parentElement.parentElement.dataset.namecode;
              let m = e.target.parentElement.parentElement.dataset.sizecode;
              this._checkout.update_item({ namecode: n, sizecode: m, quantity: 0 });
            }.bind(this));
          }.bind(this));
        } else {
          tbl.innerHTML = `<tr><div class="empty">${localizer.localize("Uw winkelwagen is leeg")}</div></tr>`;
        }
      }
    }.bind(this));
  }
  /**
  * show totals for shopping checkout
  */
  showTotals() {
    this.querySelector("span#amount").innerText = `€ ${this._checkout.item_amount.toFixed(2)}`;
    this.querySelector("shipping-form").innerText = "-";
    this.querySelector("span#total").innerText = "-";
    this.querySelector("span#vat").innerText = "-";
    if (this._checkout.can_calculate_shipping) {
      this._checkout.calculate_shipping().then(function() {
        if (this._checkout._shipping_data.error !== undefined) {
          this.querySelector("shipping-form").error = this._checkout._shipping_data.error;
        } else {
          this.querySelector("shipping-form").shipping_cost = this._checkout.shipping_cost;
        }
        let tot = this._checkout.amount;
        let vat = this._checkout.vat;
        if (tot) this.querySelector("span#total").innerText = `€ ${tot.toFixed(2)}`;
        if (vat) this.querySelector("span#vat").innerText = `€ ${vat.toFixed(2)}`;
      }.bind(this)).catch(err => console.error("shopping-cart.showTotals:", err));
    } else {
      this.querySelector("shipping-form").shipping_cost = undefined;
    }
  }
  /**
  * empty checkout items
  */
  empty() {
    this._checkout.remove_items();
  }
}
customElements.define("shopping-cart", ShoppingCart);
