import Checkout from "./Checkout.js";

class CheckoutUpdater extends HTMLElement {
  constructor() {
    super();
  }
  connectedCallback() {
    console.log("DEBUG checkout-updater:", this.dataset);
    if (this.dataset.action) {
      switch (this.dataset.action) {
        case "success":
          let c = new Checkout();
          c.remove_items();
          break;
        default:
          console.warn("checkout-updater: unknown action:", this.dataset.action);
      }
    }
  }
}
customElements.define("checkout-updater", CheckoutUpdater);
