import { model } from "./config.js";
/**
* creates country-picker element
* get countries from model and build select element
* creates 'value' property and publishes 'change' event
*/
class CountryPicker extends HTMLElement {
  constructor() {
    super();
  }
  connectedCallback() {
    this.dataset.country = this.dataset.country || "none";
    let select = document.createElement("select");
    select.id = "country";
    this.appendChild(select);
    let option = document.createElement("option");
    option.value = "none";
    option.innerText = "-";
    if (this.dataset.country === "-" || this.dataset.country === "none") option.selected = true;
    select.appendChild(option);
    for (let code of Object.keys(model.countries)) {
      option = document.createElement("option");
      option.value = code;
      if (this.dataset.country === code || this.dataset.country === model.countries[code]) option.selected = true;
      option.innerText = model.countries[code];
      select.appendChild(option);
    }
    select.addEventListener("change", function(e) {
      this.dataset.country = e.target.value;
      e.stopPropagation();
      this.dispatchEvent(new CustomEvent("change", { detail: { country: this.dataset.country } }));
    }.bind(this));
  }
  get value() {
    return this.dataset.country;
  }
  set value(v) {
    this.dataset.country = v;
    this.querySelectorAll("option").forEach(e => e.value === v ? e.selected = true : e.selected = false);
  }
}
customElements.define("country-picker", CountryPicker);
