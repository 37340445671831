import { model } from "./config.js";

class SearchBox extends HTMLElement {
  constructor() {
    super();
    this._callbacks = [];
    this._loaded = false;
    this.load();
  }
  connectedCallback() {
    model.onload(function() {
      this.innerHTML = `
          <div class="box">
            <input type="text" placeholder="${this.getAttribute("placeholder")}..." />
            <div class="right">
              <svg id="search" width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M25.8707 24.4256L24.4205 25.8758C23.9439 26.3571 23.1652 26.3571 22.6836 25.8758L17.5748 20.767C17.3445 20.5363 17.2163 20.2241 17.2163 19.8958V19.0607C15.4075 20.4751 13.1324 21.3154 10.6577 21.3154C4.77063 21.3154 0 16.5451 0 10.6577C0 4.77027 4.77063 0 10.6577 0C16.5451 0 21.3154 4.77027 21.3154 10.6577C21.3154 13.1324 20.4751 15.4075 19.0607 17.2163H19.8958C20.2241 17.2163 20.5363 17.3445 20.767 17.5748L25.8758 22.6836C26.352 23.1652 26.352 23.9439 25.8707 24.4256ZM17.2163 10.6577C17.2163 7.03006 14.2802 4.09911 10.6577 4.09911C7.03006 4.09911 4.09911 7.03516 4.09911 10.6577C4.09911 14.2853 7.03516 17.2163 10.6577 17.2163C14.2853 17.2163 17.2163 14.2802 17.2163 10.6577Z"/></svg>
            </div>
          </div>
          <div class="results"></div>
      `;
      this.querySelector("input").addEventListener("keyup", this.search.bind(this));
      this.querySelector("svg#search").addEventListener("click", this.search.bind(this));
    }.bind(this));
  }
  search(e) {
    let text = this.querySelector("input").value.toLowerCase();
    console.debug("search:",text);
    this.onload(function(data) {
      let res = []; 
      if (text !== ''){
        for (let i = 0; i < data.length; i++) {
          if (data[i].description.toLowerCase().indexOf(text) > -1) {
            res.push(data[i]);
          } else if(data[i].title.toLowerCase().indexOf(text) > -1) {
            res.push(data[i]);
          } else if (data[i].content.toLowerCase().indexOf(text) > -1) {
            res.push(data[i]);
          }
        }
        console.warn("TODO: build better search algorithm");
      }
      let resdiv= this.querySelector("div.results");
      while(resdiv.firstElementChild) resdiv.firstElementChild.remove();
      if (res.length === 0) {
        resdiv.style.display = "none";
      } else {
        for(let i = 0; i < res.length; i++) {
          resdiv.innerHTML += `<a href="${res[i].url}">${res[i].description}</a>`;
        }
        resdiv.style.display = "flex";
      }

    }.bind(this));
  }
  load() {
    fetch("/index.json").then(d=>d.json()).then(function(d){
      this._data = d;
      this._loaded = true;
      this._callbacks.forEach(c => c(d));
    }.bind(this)).catch(err => console.error("load search data:",err));
  }
  onload(callback) {
    if (this._loaded) {
      callback(this._data);
    } else {
      this._callbacks.push(callback);
    }
  }


}

customElements.define("search-box", SearchBox);
