import { data_url } from "./config.js";
import Localizer from "./Localizer.js";
const localizer = new Localizer();

/**
* data model
* loads data from file defined in settings - data_url
*/
export default class Model {
  constructor() {
    this._callbacks = [];
    this._loaded = false;
    this._data = {};
    fetch(data_url).then(d => d.json()).then(function(d) {
      this._data = d;
      if (d.localization) {
        let lok = {};
        let lang = localizer.language;
        console.error("TODO: fix localization data");
        for (let i of d.localization) {
          if (i.locale === lang) lok[i.word] = i.translation;
        }
        this._data.localization = lok;
      }
      this._loaded = true;
      this._callbacks.forEach(c => c(d));
    }.bind(this)).catch(err => console.error("Model could not load:", err));
  }
  /**
  * onload for creating callbacks
  */
  onload(callback) {
    if (this._loaded) {
      callback(this._data);
    } else {
      this._callbacks.push(callback);
    }
  }
  /**
  * get localization object key: word, value: translation
  */
  get localization() {
    return this._data.localization || {};
  }
  /**
  * get array of item classes
  */
  get items() {
    let items = [];
    for (let item of this._data.items) {
      let a = new Item(item);
      a.plant = this.findPlant(item.namecode);
      a.size = this.findSize(item.sizecode);
      items.push(a);
    }
    return items;
  }
  /**
  * find item by namecode and sizecode
  */
  findItem(namecode, sizecode) {
    let item;
    for (let i = 0; i < this._data.items.length; i++) {
      item = this._data.items[i];
      if (item.namecode === namecode && item.sizecode === sizecode) {
        let a = new Item(item);
        a.plant = this.findPlant(item.namecode);
        a.size = this.findSize(item.sizecode);
        return a;
      }
    }
    return undefined;
  }
  /**
  * returns Plant instance by namecode
  */
  findPlant(namecode) {
    let item;
    for (let i = 0; i < this._data.plants.length; i++) {
      item = this._data.plants[i];
      if (item.namecode === namecode) return new Plant(item);
    }
    return undefined;
  }
  /**
  * returns Size instance by sizecode
  */
  findSize(sizecode) {
    let item;
    for (let i = 0; i < this._data.sizes.length; i++) {
      item = this._data.sizes[i];
      if (item.sizecode === sizecode) return new Size(item);
    }
    return undefined;
  }
  /**
  * defines countries to be used in CountryPicker/Checkout
  */
  get countries() {
    return {
      "NL": "Nederland",
      "DE": "Deutschland",
      "BE": "Belgie",
      "LU": "Luxembourg",
      "AT": "Österreich",
      "CH": "Schweiz",
      "FR": "France",
      "DK": "Denmark",
      "SE": "Sweden",
      "PL": "Poland",
      "SI": "Slovenia",
      "HR": "Croatia",
    };
  }
}

/**
* Plant class with localization
*/
class Plant {
  constructor(data) {
    Object.assign(this, data);
  }
  get local_name() {
    if (!this._local_name) {
      let l = localizer.language;
      switch (l) {
        case 'de':
          this._local_name = this.german_name;
          break;
        case 'en':
          this._local_name = this.english_name;
          break;
        default:
          this._local_name = this.dutch_name;
      }
    }
    return this._local_name
  }
}
/**
* Size class with localization
*/
class Size {
  constructor(data) {
    Object.assign(this, data);
  }
  get local_pot() {
    if (!this._local_pot) this._local_pot = localizer.localize(this.pot);
    return this._local_pot;
  }
  get local_size() {
    if (!this._local_size) this._local_size = localizer.localize(this.size);
    return this._local_size;
  }
}
/**
* Item class with plant and size subclasses
*/
class Item {
  constructor(data) {
    Object.assign(this, data);
  }
  get plant() {
    return this._plant;
  }
  set plant(p) {
    this._plant = p;
  }
  get size() {
    return this._size;
  }
  set size(m) {
    this._size = m;
  }
}
