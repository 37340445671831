import { model } from "./config.js";
/**
* Class to localize text to document language
* only use after model.load event
*/
export default class Localizer {
  constructor() {
  }
  /**
  * localize text to current language. 
  * keep capitalization.
  */
  _localize(w) {
    let decapitalized = w.charAt(0).toLowerCase() + w.slice(1);
    let r = model.localization[decapitalized] || w;
    if (w.charAt(0) === w.charAt(0).toUpperCase()) r = r.charAt(0).toUpperCase() + r.slice(1);
    return r;
  }
  /** 
  * localize html element or text, keep capitalization
  */
  localize(e) {
    if (e instanceof HTMLElement){
      e.querySelectorAll("[data-localize]").forEach(function(el){
        if (el instanceof HTMLInputElement) {
          el.value=this._localize(el.dataset.localize);
        } else {
          el.innerText = this._localize(el.dataset.localize);
        }
        el.removeAttribute("data-localize");
      }.bind(this));
      return e;
    } else {
      return this._localize(e);
    }
  }
  /**
  * get language from document or from browser
  */
  get language() {
    let l = document.querySelector("html").getAttribute("lang");
    if (l) return l;
    l = navigator.language.substring(0, 2);
    return l || "nl";
  }
}

