import { model } from "./config.js";

class SearchBox extends HTMLElement {
  constructor() {
    super();
    this._callbacks = [];
    this._loaded = false;
    this.load();
  }
  connectedCallback() {
    model.onload(function() {
      this.innerHTML = `
          <div class="box">
            <div class="left"></div>
            <input type="text" placeholder="${this.getAttribute("placeholder")}..." />
            <div class="right"><i class="material-icons">search</i></div>
          </div>
          <div class="results"></div>
      `;
      this.querySelector("input").addEventListener("keyup", this.search.bind(this));
      this.querySelector("i.material-icons").addEventListener("click", this.search.bind(this));
    }.bind(this));
  }
  search(e) {
    let text = this.querySelector("input").value.toLowerCase();
    console.debug("search:",text);
    this.onload(function(data) {
      let res = []; 
      if (text !== ''){
        for (let i = 0; i < data.length; i++) {
          if (data[i].description.toLowerCase().indexOf(text) > -1) {
            res.push(data[i]);
          } else if(data[i].title.toLowerCase().indexOf(text) > -1) {
            res.push(data[i]);
          } else if (data[i].content.toLowerCase().indexOf(text) > -1) {
            res.push(data[i]);
          }
        }
        console.warn("TODO: build better search algorithm");
      }
      let resdiv= this.querySelector("div.results");
      while(resdiv.firstElementChild) resdiv.firstElementChild.remove();
      if (res.length === 0) {
        resdiv.style.display = "none";
      } else {
        for(let i = 0; i < res.length; i++) {
          resdiv.innerHTML += `<a href="${res[i].url}">${res[i].description}</a>`;
        }
        resdiv.style.display = "flex";
      }

    }.bind(this));
  }
  load() {
    fetch("/index.json").then(d=>d.json()).then(function(d){
      this._data = d;
      this._loaded = true;
      this._callbacks.forEach(c => c(d));
    }.bind(this)).catch(err => console.error("load search data:",err));
  }
  onload(callback) {
    if (this._loaded) {
      callback(this._data);
    } else {
      this._callbacks.push(callback);
    }
  }


}

customElements.define("search-box", SearchBox);
