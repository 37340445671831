import { model, vat_low, vat_high, api_url } from "./config.js";

export default class Checkout {
  constructor() {
    let c = localStorage.getItem("checkout");
    if (c) {
      c = JSON.parse(c);
      if (c) Object.assign(this, c);
    }
  }
  /**
   * save item to localStorage and dispatch cart_change event
   */
  save() {
    localStorage.setItem("checkout", JSON.stringify(this.data));
    document.dispatchEvent(new CustomEvent("checkout_change", { detail: { checkout: this } }));
  }
  /**
  * add item to cart or increase quantity if it already is in cart
  */
  add_item(item) { //add item to list or increase quantity
    if (!this.items) this.items = [];
    if (!item.quantity) throw "no quantity";
    if (!item.namecode) throw "no namecode";
    if (!item.sizecode) throw "no sizecode";
    item = {
      quantity: item.quantity,
      namecode: item.namecode,
      sizecode: item.sizecode
    }
    if (typeof item.quantity === "string") item.quantity = parseInt(item.quantity, 10);
    let exists = false;
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].namecode === item.namecode && this.items[i].sizecode === item.sizecode) {
        this.items[i].quantity += item.quantity;
        exists = true;
        break;
      }
    }
    if (!exists && item.quantity > 0) this.items.push(item);
    document.dispatchEvent(new CustomEvent("checkout_item_change", { detail: { items: this.items } }));
    this.save();
  }
  /**
  * update item in cart, overwriting quantity. remove if quantity is 0
  */
  update_item(item) { //update item
    if (!this.items) this.items = [];
    if (item.quantity === undefined) throw "no quantity";
    if (item.namecode === undefined) throw "no namecode";
    if (item.sizecode === undefined) throw "no sizecode";
    item = {
      quantity: item.quantity,
      namecode: item.namecode,
      sizecode: item.sizecode
    }
    if (typeof item.quantity === "string") item.quantity = parseInt(item.quantity, 10);
    let exists = false;
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].namecode === item.namecode && this.items[i].sizecode === item.sizecode) {
        this.items[i].quantity = item.quantity;
        if (this.items[i].quantity === 0) this.items.splice(i, 1);
        exists = true;
        break;
      }
    }
    if (!exists && item.quantity > 0) this.items.push(item);
    document.dispatchEvent(new CustomEvent("checkout_item_change", { detail: { items: this.items } }));
    this.save();
  }
  /**
   * get number of items in cart
   */
  get count() {
    if (!this.items) return 0;
    let cnt = 0;
    for (let i = 0; i < this.items.length; i++) {
      cnt += this.items[i].quantity;
    }
    return cnt;
  }
  /**
  * get items with localized data
  */
  get local_items() {
    let local_items = [];
    let i, art;
    if (this.items && this.items.length > 0) {
      //for (i = 0; i < this.items.length; i++) {
      for (i = this.items.length - 1; i > -1; i--) {
        art = model.findItem(this.items[i].namecode, this.items[i].sizecode);
        if (!art) {
          console.error("item not found:", this.items[i]);
          this.items.splice(i, 1);
          this.save();
        } else {
          art = Object.assign(art, this.items[i]);
          local_items.push(art);
        }
      }
    }
    local_items.sort(function(a, b) {
      if (a.plant.local_name === b.plant.local_name) {
        if (a.plant.latin_name === b.plant.latin_name) {
          return a.sizecode > b.sizecode ? 1 : -1;
        } else {
          return a.plant.latin_name > b.plant.latin_name ? 1 : -1;
        }
      } else {
        return a.plant.local_name > b.plant.local_name ? 1 : -1;
      }
    });
    return local_items;
  }
  /** remove all items from cart */
  remove_items() {
    this.items = [];
    document.dispatchEvent(new CustomEvent("checkout_item_change", { detail: { items: this.items } }));
    this.save();
  }
  /** calculate amount for items in cart */
  get item_amount() {
    let total = 0.0;
    let a = this.local_items;
    if (a === undefined) return total;
    for (let i = 0; i < a.length; i++) {
      total += a[i].quantity * parseFloat(a[i].price);
    }
    return total;
  }
  /** get vat for items */
  get item_vat() {
    let vat = 0.0;
    for (let item of this.local_items) vat += (item.quantity * item.price) * (vat_low / 100);
    return vat;
  }
  get can_calculate_shipping() {
    if (this.ship_postalcode === undefined && this.postalcode === undefined) return false;
    if (this.ship_country === undefined && this.country === undefined) return false;
    if (this.items === undefined || this.items.length === 0) return false;
    return true;
  }
  /** calculate shipping cost on backend */
  calculate_shipping() {
    return new Promise(function(resolve, reject) {
      let url = api_url + "/shipping";
      let opts = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ items: this.items, postalcode: this.ship_postalcode || this.postalcode, country: this.ship_country || this.country })
      };
      fetch(url, opts).then(function(response) {
        return response.json();
      }).then(function(data) {
        this._shipping_data = data;
        document.dispatchEvent(new CustomEvent("calculated_shipping", { detail: this }));
        resolve(data);
      }.bind(this)).catch(function(err) {
        reject(err);
      });
    }.bind(this));
  }
  /** get shipping_cost */
  get shipping_cost() {
    if (this._shipping_data === undefined) return undefined;
    return this._shipping_data.shipping_cost;
  }
  /** get vat for shipping */
  get shipping_vat() {
    if (this._shipping_data === undefined) return undefined;
    let vat = this._shipping_data.shipping_cost * (vat_high / 100);
    return vat;
  }
  /** get item total + shipping */
  get amount() {
    let a = this.item_amount;
    let s = this.shipping_cost;
    if (s === undefined) return undefined;
    return a + s;
  }
  /** get item vat + shipping vat */
  get vat() {
    let i = this.item_vat;
    let s = this.shipping_vat;
    if (s === undefined) return undefined;
    return i + s;
  }
  /** validate checkout */
  get valid() {
    if (this.accept_conditions === undefined || this.accept_conditions === "0") return "conditions";
    if ((this.ship_address === undefined || this.ship_address.length === 0) && (this.address === undefined || this.address.length === 0)) return "address";
    if ((this.first_name === undefined || this.first_name.length === 0) && (this.last_name === undefined || this.last_name.length === 0)) return "name"
    if (this.items === undefined || this.items.length === 0) return "items";
    if (this._shipping_data === undefined || this._shipping_data.error !== undefined || this.shipping_cost === undefined || this.shipping_cost === 0) return "totals";
    return "valid";
  }
  get data() {
    let data = {};
    for (let key of Object.keys(this)) {
      if (key.indexOf("_") !== 0) {
        data[key] = this[key];
      }
    }
    data.origin = window.location.origin;
    return data;
  }
  async place_order() {
    let order = this.data;
    let url = api_url + "/order";
    let result;
    console.debug("placing order:", order);
    let opts = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(order)
    };
    try {
      result = await fetch(url, opts).then((response) => response.json());
      console.debug("placed order:", result);
      if (result.redirectURL !== undefined) window.location.href = result.redirectURL;
    } catch (err) {
      console.error("place_order:", err);
      alert(err);
    }
  }
}
